import React from 'react';
import loadable from '@loadable/component';
import { theme } from '../styles/theme';
import { ThemeProvider } from '../styles/ThemeProvider';

import WholePageOpacity from '../animations/whole-page-opacity';
const Layout = loadable(() => import('../components/Layout'));
const PraiseReportsMainComponent = loadable(() =>
  import('../components/praise-reports/praise-reports-main'),
);
const Head = loadable(() => import('../components/Head'));

const PraiseReports = () => {
  return (
    <ThemeProvider theme={theme}>
      <Layout>
        <Head title="Praise Reports | GWOS" />
        <WholePageOpacity>
          <PraiseReportsMainComponent />
        </WholePageOpacity>
      </Layout>
    </ThemeProvider>
  );
};

export default PraiseReports;
