import React from 'react'
import { motion } from 'framer-motion'

const WholePageOpacity = ({ children }) => {

    return (
        <motion.div
            initial={{opacity:0}}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5, ease: "easeOut", duration: 2, type: 'spring', stiffness: 120   }}
        >{children}</motion.div>
    )
}
export default WholePageOpacity;